import { Outlet } from "react-router-dom";
import Footer from "./footer";
import TopNav from "./top-nav";
import { MainProvider } from "../utils/context/main-context";
import BottomBar from "./bottom-bar";

function HomeLayout() {
  return (
    <>
      <MainProvider>
        <BottomBar />
        <div className="relative min-h-screen flex flex-col justify-between">
          <TopNav />
          <main className="cmaths-main">
            <Outlet />
          </main>
          <Footer />
        </div>
      </MainProvider>
    </>
  );
}

export default HomeLayout;
